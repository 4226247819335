@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h3>ข้อมูลผู้ใช้งาน</h3>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">username</p></v-col>
      <v-col cols="4">
        <v-text-field v-model="username" outlined dense disabled></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col cols="2"><p class="pt-2">password</p></v-col> -->
      <!-- <v-col cols="4">
        <v-text-field
          v-model="password"
          outlined
          dense
        ></v-text-field>
      </v-col> -->
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">ชื่อ</p></v-col>
      <v-col cols="4">
        <v-text-field v-model="name" outlined dense disabled></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <v-text-field v-model="tel" outlined dense disabled></v-text-field>
      </v-col>
      <v-col cols="2"><p class="pt-2">อีเมล</p></v-col>
      <v-col cols="4">
        <v-text-field v-model="email" outlined dense disabled></v-text-field>
      </v-col>
      <v-col cols="2"><p class="pt-2">ประเภท</p></v-col>
      <v-col cols="4">
        <v-text-field v-model="type" outlined dense disabled></v-text-field>
      </v-col>
      <!-- <v-col cols="2"><p class="pt-2">verify</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="isVerify"
          label="verify"
          outlined
          dense
        ></v-text-field>
      </v-col> -->
      <v-col cols="2"><p class="pt-2">รูปโปรไฟล์</p></v-col>
      <v-col cols="4">
        <v-img :src="profileImg"></v-img>
      </v-col>
      <v-col cols="2"><p class="pt-2">รูปยืนยันตัวตน</p></v-col>
      <v-col cols="4">
        <v-img :src="evidentImg" v-if="evidentImg"></v-img>
      </v-col>
      <!-- <v-col cols="2"><p class="pt-2">idCargImg</p></v-col>
      <v-col cols="4">
        idCargImg
      </v-col>
      <v-col cols="2"><p class="pt-2">idCargImg2</p></v-col>
      <v-col cols="4">
        idCargImg2
      </v-col>
      <v-col cols="2"><p class="pt-2">activeFlag</p></v-col>
      <v-col cols="4">
        activeFlag
      </v-col> -->
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="6">
        <h2 class="pt-2">อนุมัติการยืนยันตัวตน</h2>
        <v-select
          v-model="isVerify"
          label="อนุมัติการยืนยันตัวตน"
          :items="itemsapproveFlag"
          item-text="text"
          item-value="value"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()">บันทึก</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      username: "",
      password: "",
      name: "",
      tel: "",
      email: "",
      profileImg: "",
      type: "",
      isVerify: "",
      idCargImg: "",
      idCardImg2: "",
      activeFlag: "",
      evidentImg: "",
      itemsapproveFlag: [
        { text: "ยังไม่อนุมัติ", value: "0" },
        { text: "อนุมัติ", value: "1" },
      ],
      isVerify: false,
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.username = userdata.username;
    this.password = userdata.password;
    this.name = userdata.name;
    this.tel = userdata.tel;
    this.email = userdata.email;
    this.profileImg = userdata.profileImg;
    this.type = userdata.type;
    this.isVerify = userdata.isVerify;
    this.idCargImg = userdata.idCargImg;
    this.idCardImg2 = userdata.idCardImg2;
    this.activeFlag = userdata.activeFlag;
    activeFlag: true;
    // count: 1
    // createdAt: "2021-06-23T04:30:30.000Z"
    // email: "nutthapon.donut@gmail.com"
    // id: 8
    // idCardImg: "1"
    // idCardImg2: "1"
    // isVerify: false
    // name: "donut admin"
    // password: "$2b$10$fRh0aT8sZPQHN3FU1R.3Dekd/0Ex.3oUTv9K3K3yfUH/9j.UUpdyW"
    // profileImg: "https://forrent-service.yuzudigital.com/profile-images/profile-2AytBvrW.jpg"
    // tel: "0873576012"
    // type: "ADMIN"
    // updatedAt: "2021-06-23T04:30:30.000Z"
    // username: "admin1"
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
    async submit() {
      if(this.isVerify == '1'){
        const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/${this.id}/approve`,
        {}
      );
      console.log("response product", response);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `อนุมัติสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("ManageUser");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `อนุมัติไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      }
    },
  },
};
</script>
